import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Name","required":""},model:{value:(_vm.nameModel),callback:function ($$v) {_vm.nameModel=$$v},expression:"nameModel"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSelect,{attrs:{"items":['1.5', '1.6'],"label":"Version","required":""},model:{value:(_vm.versionModel),callback:function ($$v) {_vm.versionModel=$$v},expression:"versionModel"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSelect,{attrs:{"items":_vm.availableTypes,"label":"Type","required":""},model:{value:(_vm.typeModel),callback:function ($$v) {_vm.typeModel=$$v},expression:"typeModel"}})],1),_c(VCol,{attrs:{"cols":"12"}},[(!_vm.typeModel.valueOf().startsWith('AWS_'))?_c('div',[_c(VTextField,{attrs:{"label":"Url"},model:{value:(_vm.urlModel),callback:function ($$v) {_vm.urlModel=$$v},expression:"urlModel"}})],1):_vm._e(),(_vm.typeModel.valueOf() === 'AWS_SNS')?_c('div',[_c('p',[_vm._v(" AWS SNS topics are configured with server settings. "),_c('br'),_vm._v(" Please check the documentation for more information. ")])]):_vm._e(),(_vm.typeModel.valueOf() === 'AWS_IOT')?_c('div',[_c('p',[_vm._v(" AWS IoT connections are configured with server settings. "),_c('br'),_vm._v(" Please check the documentation for more information. ")])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }