
import {Component, Vue} from "vue-property-decorator";

import RouteDialog from "@/components/RouteDialog.vue";
import {CentralSystem, RouteDialogConfirmEvent} from "@/domain";
import CentralSystemForm from "@/components/CentralSystemForm.vue";

@Component({
  components: {RouteDialog, CentralSystemForm},
  data: () => ({
    config: undefined,
  })
})
export default class NewCentralSystemDialog extends Vue {
  centralSystem: Partial<CentralSystem> = {
    name: '',
    ocppVersion: '1.6',
    type: 'WEBSOCKET'
  };

  async mounted() {
    await this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
  }

  async onConfirm(evt: RouteDialogConfirmEvent) {
    try {
      await this.$store.dispatch("centralSystems/createOne", this.centralSystem);
      evt.done();
    } catch (e) {
      console.log("An error occurred while creating: ", e);
      evt.cancel();
    }
  }
}
