

import {Component, Prop, PropSync, Vue} from "vue-property-decorator";
import Config from "@/domain/config";

@Component
export default class CentralSystemForm extends Vue {

  @PropSync('name', {type: String, required: true})
  readonly nameModel!: string;

  @PropSync('version', {type: String, required: true})
  readonly versionModel!: string;

  @PropSync('type', {type: String, required: true})
  readonly typeModel!: string;

  @PropSync('url', {type: String})
  readonly urlModel!: string;

  @Prop()
  readonly config: Config | undefined;

  get availableTypes(): string[] {
    const types = ['SOAP', 'WEBSOCKET'];
    if (this.config) {
      if (this.config.isAwsSnsAvailable) {
        types.push('AWS_SNS');
      }
      if (this.config.isAwsIotAvailable) {
        types.push('AWS_IOT');
      }
    }
    return types;
  }

}
